<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-tab-panel
          :module_name="page.name"
          :record="record"
          @change="save"
          @archive="archive"
          :loading="loading"
          :model="page.model">
          <template v-slot:main_fields>
            <v-text-field
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('name')"
                v-model="record.name">
              <template #label>{{ $store.getters.translate('name') }}<span class="red--text"><strong>*</strong></span></template>
            </v-text-field>
            <v-select
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('metabase_dashboard')"
                v-model="record.metabase_dashboard"
                :items="metabase_dashboards"
                v-if="show_metabase"
                clearable/>
            <v-simple-table class="pa-0 ma-0" fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th></th>
                    <th v-for="(role_action, index) in roles_action" :key="index">
                      <v-checkbox
                        style="display: inline-block"
                        v-model="bulk_action"
                        :value="role_action"
                        @change="updateAllCheckboxes"/>
                      <label>{{ $store.getters.translate(role_action) }}</label>
                    </th>
                    <th>{{ $store.getters.translate("add_all") }}</th>
                  </tr>
                </thead>
                <tbody class="pa-0 ma-0">
                  <tr v-for="(permission, index) in translate(permission_names)" :key="index">
                    <td>{{ $store.getters.translate(permission.label) }}</td>
                    <td v-for="(role_action, index2) in roles_action" :key="index2">
                      <v-checkbox
                        v-model="record.permissions"
                        :value="role_action + '_' + permission.value"
                        @change="save"/>
                    </td>
                    <td>
                      <v-btn :disabled="hasAll(permission.value)" @click="saveAll(permission.value)" icon>
                        <v-icon> mdi-form-select </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";

export default {
  components: {
    BaseTabPanel,
    BaseBreadcrumb,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("roles"),
        name: "roles",
        model:'role'
      },
      record: {
        name: "",
        all_permissions: [],
        permissions: [],
      },
      loading: false,
      fields: [],
      roles_action: ["show", "create", "edit", "delete"],
      bulk_action: [],
      permission_names: [],
      metabase_dashboards: [],
      metabase_dashboard_names: [],
      show_metabase: false,
    };
  },
  created() {
    this.load();
    this.getMetabaseDashboards();
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.$route.params.id)
        .then((response) => {
          this.record = response.data;
          this.permission_names = this.getPermissionNames(this.record.all_permissions);
          this.record.permissions = this.getUserPermissions(this.record.permissions);
          this.updateBulkActions();
          this.loading = false;
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    getMetabaseDashboards() {
      this.$http
        .get(this.$store.getters.appUrl + "v2/metabase-dashboards")
        .then((response) => {
          this.metabase_dashboards.push({
            value: -1,
            text: this.$store.getters.translate("no_metabase_dashboard"),
          });
          for (let i = 0; i < response.data.length; i++) {
            this.metabase_dashboards.push({
              value: response.data[i].id,
              text: response.data[i].name,
            });
            this.metabase_dashboard_names[response.data[i].id] = response.data[i].name;
          }
          this.show_metabase = true;
        })
        .catch((error) => {
          if (error.response.data.error) {
            this.show_metabase = false;
            this.$store.dispatch("setMetabaseDashboard", null);
          }
        });
    },
    save() {
      if(!this.loading) {
        let error = false;
        if (!this.record.name || this.record.name === '') {
          this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate('name'));
          error = true;
        }
        if (!error) {
          this.loading = true;
          if (this.record.metabase_dashboard == -1) {
            this.record.metabase_dashboard = null;
            this.record.metabase_dashboard_name = null;
          } else {
            this.record.metabase_dashboard_name = this.metabase_dashboard_names[this.record.metabase_dashboard];
          }
          this.$http
              .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.updateBulkActions();
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
        }
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure?")).then((res) => {
          if (res) {
            this.loading = true;
            this.$http
              .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id)
              .then(() => {
                this.$router.push("/" + this.page.name);
                this.loading = false;
                this.$toasted.success(this.$store.getters.translate("successfully_deleted"));
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
          }
        }
      );
    },
    getPermissionNames(all_permissions) {
      let response = [];
      let permission_name = "";
      all_permissions.forEach(function (item) {
        permission_name = item.name.substring(item.name.indexOf("_") + 1);
        if (!response.includes(permission_name)) {
          response.push(permission_name);
        }
      });
      response.sort();
      return response;
    },
    getUserPermissions(permissions) {
      let response = [];
      permissions.forEach(function (item) {
        response.push(item.name);
      });
      return response;
    },
    updateBulkActions() {
      let amount_of_permissions = this.permission_names.length;
      let permissions = this.record.permissions;
      var counter = {
        show: 0,
        create: 0,
        edit: 0,
        delete: 0,
      };
      this.permission_names.forEach(function (item) {
        if (permissions.includes("show_" + item)) {
          counter.show++;
        }
        if (permissions.includes("create_" + item)) {
          counter.create++;
        }
        if (permissions.includes("edit_" + item)) {
          counter.edit++;
        }
        if (permissions.includes("delete_" + item)) {
          counter.delete++;
        }
      });
      for (const [key, value] of Object.entries(counter)) {
        if (value == amount_of_permissions) {
          if (!this.bulk_action.includes(key)) {
            this.bulk_action.push(key);
          }
        } else if (this.bulk_action.includes(key)) {
          this.bulk_action.splice(this.bulk_action.indexOf(key), 1);
        }
      }
    },
    updateAllCheckboxes() {
      var permissions = this.record.permissions;
      let bulk_action = this.bulk_action;
      let permission_names = this.permission_names;
      this.roles_action.forEach(function (role_action) {
        let selected = bulk_action.includes(role_action);
        permission_names.forEach(function (item) {
          if (selected && !permissions.includes(role_action + "_" + item)) {
            permissions.push(role_action + "_" + item);
          } else if (!selected && permissions.includes(role_action + "_" + item)) {
            permissions.splice(permissions.indexOf(role_action + "_" + item), 1);
          }
        });
      });
      this.record.permissions = permissions;
      this.save();
    },
    translate(list) {
      let result = [];
      list.forEach((row) => {
        result.push({
          label: this.$store.getters.translate(row),
          value: row,
        });
      });
      return this.$lodash.sortBy(result, "label");
    },
    saveAll(model) {
      if (!this.hasAll(model)) {
        console.log("adding");
        this.record.permissions.push("show_" + model);
        this.record.permissions.push("edit_" + model);
        this.record.permissions.push("create_" + model);
        this.record.permissions.push("delete_" + model);
      } else {
        console.log("removing");
        this.$lodash.remove(this.record.permissions, function (permission) {
          return (permission === "show_" + model || permission === "edit_" + model || permission === "create_" + model || permission === "delete_" + model);
        });
        this.load();
      }
      this.save();
    },
    hasAll(model) {
      let has_all = 0;
      let options = ["create", "edit", "delete", "show"];
      options.forEach((option) => {
        let found = this.record.permissions.find((permission) => permission == option + "_" + model);
        if (found) {
          has_all = has_all + 1;
        }
      });
      if (has_all === 4) {
        return true;
      }
      return false;
    },
  },
  computed: {
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
};
</script>